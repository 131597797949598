import React from 'react';
import _ from 'lodash';

import DateRange from '../components/DateRange';

import {Layout} from '../components/index';
import {getPages, Link, safePrefix} from '../utils';

export default class Showcase extends React.Component {
    render() {
        let display_projects = _.orderBy(
          getPages(this.props.pageContext.pages, "/projects"),
          [
            function (object) {
              if (object.frontmatter.end) {
                return new Date(object.frontmatter.end).getTime();
              } else {
                return new Date();
              }
            },
            function (object) {
              return new Date(object.frontmatter.start).getTime();
            },
          ],
          ["desc", "desc"]
        );

        return (
          <Layout {...this.props}>
            <div className="project-feed">
              {_.map(display_projects, (project, project_idx) => (
                <article key={project_idx} className="project">
                  <div
                    className={
                      "project-inside" +
                      (_.get(project, "frontmatter.highlight")
                        ? " project-highlight"
                        : "")
                    }
                  >
                    {_.get(project, "frontmatter.thumb_img_path") && (
                      <Link
                        className="project-thumbnail"
                        to={safePrefix(_.get(project, "url"))}
                      >
                        <img
                          className="thumbnail"
                          src={safePrefix(
                            _.get(
                              project,
                              "frontmatter.thumb_img_path"
                            ).replace(/\.png|\.jpg$/, ".webp")
                          )}
                          alt={_.get(project, "frontmatter.title")}
                        />
                      </Link>
                    )}
                    <header className="project-header">
                      <h2 className="project-title">
                        <Link
                          to={safePrefix(_.get(project, "url"))}
                          rel="bookmark"
                        >
                          {_.get(project, "frontmatter.title")}
                        </Link>
                      </h2>
                    </header>
                    <div className="project-content">
                      <cite className="citeblock">
                        {_.get(project, "frontmatter.subtitle")}
                      </cite>
                      <p className="">
                        {_.get(project, "frontmatter.excerpt").substring(
                          0,
                          100
                        )}
                        ...
                      </p>
                    </div>
                    <footer className="project-meta">
                      <DateRange {...project}></DateRange>
                    </footer>
                  </div>
                </article>
              ))}
            </div>
          </Layout>
        );
    }
}
